<!-- adding progress-bar till all appsettings are loaded , to prevent user clicking buttons before data loading-->
<div *ngIf="!allContentLoaded" class="bar_overlay"></div>
<mat-progress-bar
  mode="indeterminate"
  *ngIf="!allContentLoaded"
></mat-progress-bar>
<div class="mt-0 pt-3 px-5">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-5">
        <span
          *ngIf="lockbuttons"
          class="alert"
          [ngClass]="'alert-locked'"
          [matTooltip]="appSettings.bannerToolTip"
        >
          <mat-icon style="font-size: x-large">lock</mat-icon
          >{{ appSettings.bannerMainText }}
        </span>
      </div>
    </div>
    <!-- BUTTONS AND CARDS-->
    <div class="d-flex flex-row flex-wrap align-items-start mb-3">
      <!-- BUTTONS -->
      <div
        id="button-wrapper"
        class="d-flex flex-row align-items-start flex-gap-24px flex-wrap mr-auto pr-3 mb-3"
      >
        <button
          class="btn btn-success p-2 m-0"
          routerLink="/project-details/0"
          [disabled]="lockbuttons"
        >
          <mat-icon>add</mat-icon> New Project
        </button>
        <button
          class="btn btn-outline-success bg-white p-2 m-0"
          [routerLink]="['/myapprovals']"
        >
          <mat-icon>done</mat-icon> My Approvals
        </button>
        <button
          class="btn btn-outline-success bg-white p-2 m-0"
          (click)="goToUrl(appSettings.powerBiReportsLink)"
        >
          <mat-icon>payments</mat-icon> PBI Reports
        </button>
        <button
          class="btn btn-outline-success bg-white p-2 m-0"
          (click)="goToUrl(appSettings.trainingLink)"
        >
          <mat-icon>menu_book</mat-icon> Training
        </button>
        <!-- SIFT AI button link-->
        <div class="button-tooltip-wrapper">
          <button
            class="btn btn-success p-2 m-0 shine"
            (click)="goToUrl(appSettings.siftAiLink)"
          >
            <mat-icon>forum</mat-icon> SIFT AI Chatbot
          </button>
          <div
            id="sift-ai-tooltip"
            class="tooltip-content"
          >
            <h3>Try the SIFT AI Chatbot</h3>
            <p>
              Answer questions about SIFT projects, purchase orders, and more in
              the new AI-powered application.
            </p>
            <div class="w-100 d-flex flex-row justify-content-end">
              <button
                class="btn btn-success p-2 m-0"
                (click)="closeSiftAiTooltip()"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- CARDS -->
      <div class="d-flex flex-row align-items-start flex-wrap flex-gap-24px">
        <div class="card text-dark card-styles content-wrapping-card my-0">
          <div class="card-header">
            <p class="card-category text-dark">Total Amount</p>
            <h2 class="card-title text-dark">{{ totalAmount }}</h2>
          </div>
        </div>
        <div class="card text-dark card-styles content-wrapping-card my-0">
          <div class="card-header">
            <p class="card-category text-dark">Project Count</p>
            <h2 class="card-title text-dark">{{ projectCount }}</h2>
          </div>
        </div>
        <div class="card text-dark card-styles content-wrapping-card my-0">
          <div class="card-header">
            <p class="card-category text-dark">Pending Approval</p>
            <h2 class="card-title text-dark">{{ pendingApprovalCount }}</h2>
          </div>
        </div>
        <div class="card text-dark card-styles content-wrapping-card my-0">
          <div class="card-header">
            <p class="card-category text-dark">Approved</p>
            <h2 class="card-title text-dark">{{ approvedCount }}</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTIVATIONS TABLE -->
    <div class="card activation-card m-0">
      <div class="card-header card-header-primary card-header-icon mb-2 p-0">
        <h2 class="card-title text-dark">Activations</h2>
      </div>
      <div class="card-body p-0">
        <div class="toolbar px-2" style="vertical-align: middle">
          <!-- Here you can write extra buttons/actions for the toolbar -->
          <span class="d-flex flex-row flex-wrap mb-2" style="row-gap: 0.25rem">
            <div
              *ngIf="allDataLoaded == true"
              class="input-group border border-secondary border-rounded"
              style="width: 20%"
            >
              <div class="input-group-prepend bg-white left-border-rounded">
                <span class="input-group-text" id="basic-addon1"
                  ><i class="material-icons">search</i></span
                >
              </div>
              <input
                type="text"
                class="form-control transparentBg bg-white right-border-rounded"
                placeholder="SEARCH"
                aria-label="SEARCH"
                aria-describedby="basic-addon1"
                (focusout)="onBlurSearchMethod($event)"
                (keydown.enter)="onBlurSearchMethod($event)"
                (keyup)="autoSearchTable($event)"
              />
            </div>
            <div
              *ngIf="selectedOptions?.length"
              class="d-flex flex-row flex-wrap"
              style="row-gap: 0.25rem"
            >
              <div *ngFor="let selection of selectedOptions" class="px-1">
                <button
                  mat-raised-button
                  class="border border-secondary"
                  (click)="removeFilter(selection.filterValue)"
                >
                  <mat-icon>close</mat-icon>
                  {{ selection.filterValue }}
                </button>
              </div>
              <button
                mat-raised-button
                class="border border-secondary"
                (click)="closeDropDown()"
              >
                <mat-icon>close</mat-icon> Clear All
              </button>
            </div>
            <button
              mat-raised-button
              *ngIf="allDataLoaded == true"
              class="border border-secondary title-style"
              (click)="openFilter()"
              style="margin-left: auto; margin-right: 0%; border-radius: 0.3rem"
            >
              <mat-icon class="mr-2" *ngIf="!isClicked">filter_list</mat-icon>
              <mat-icon class="mr-2" *ngIf="isClicked"
                >keyboard_arrow_left</mat-icon
              >FILTERS
            </button>
          </span>
        </div>
        <mat-progress-bar
          *ngIf="allDataLoaded == false"
          mode="indeterminate"
        ></mat-progress-bar>

        <div
          class="bg-white d-flex flex-row-reverse px-2"
          style="border-radius: 0.25rem"
        >
          <div
            *ngIf="isClicked"
            class="p-2"
            style="width: 20%; position: absolute; z-index: 999; overflow: auto"
          >
            <div
              class="border box-border bg-white mat-elevation-z8"
              style="width: 100%"
            >
              <mat-accordion displayMode="flat" multi class="mat-table">
                <section
                  class="mat-elevation-z2 mat-header-row py-2 pl-4 d-flex flex-row"
                  style="vertical-align: middle"
                >
                  <span
                    class="mat-header-cell pt-2"
                    style="font-size: larger; font-weight: bold; color: black"
                    >Filter Options</span
                  >
                  <span
                    style="margin-left: auto; margin-right: 0%"
                    class="pr-2"
                  >
                    <button
                      mat-raised-button
                      class="btn btn-outline-success p-1"
                      (click)="closeDropDown()"
                    >
                      Clear All
                    </button>
                  </span>
                </section>
                <div *ngFor="let itemList of FilterList; let i = index">
                  <mat-expansion-panel *ngIf="i + 1 != FilterList.length">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ itemList[0] }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="scrollable-div">
                      <mat-selection-list
                        #items
                        *ngFor="let filter of itemList; let j = index"
                        (selectionChange)="
                          filterItemsFunction(itemList, $event)
                        "
                      >
                        <mat-list-option
                          *ngIf="j != 0"
                          multiple
                          checkboxPosition="before"
                          style="font-size: smaller"
                          [value]="filter"
                          [selected]="getSelected(filter)"
                        >
                          {{ filter }}
                        </mat-list-option>
                      </mat-selection-list>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="i + 1 == FilterList.length">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ itemList[0] }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="scrollable-div">
                      <mat-selection-list
                        #items
                        *ngFor="let filter of itemList; let j = index"
                        (selectionChange)="
                          filterItemsFunction(itemList, $event)
                        "
                      >
                        <mat-list-option
                          *ngIf="j != 0"
                          checkboxPosition="before"
                          style="font-size: smaller"
                          [value]="filter"
                          [selected]="getSelected(filter)"
                        >
                          {{ filter }}
                        </mat-list-option>
                      </mat-selection-list>
                    </div>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>
          </div>
          <div class="table-responsive p-1">
            <table
              id="datatables"
              #datatables
              style="width: 100%"
              class="table table-striped display nowrap"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
